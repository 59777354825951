

































































import { auth } from "@/services/firebase";
import store from "@/store/store";
import router from "@/router";
import Vue from "vue";

export default Vue.extend({
  name: "MainMenu",
  data() {
    return {
      menuItemActive: "/",
      menuItems: [
        {
          title: "Kunder",
          path: "/",
          icon: "mdi-target-account",
        },
        {
          title: "Leksehjelp",
          path: "projects",
          icon: "mdi-account-switch",
        },
        {
          title: "Kodekurs",
          path: "teams",
          icon: "mdi-microsoft-xbox-controller",
        },
        {
          title: "Lærere",
          path: "sellers",
          icon: "mdi-account-star",
        },
        {
          title: "Feedback",
          path: "news",
          icon: "mdi-message-alert-outline",
        },
        {
          title: "Leksehjelp-timer",
          path: "lessons",
          icon: "mdi-clock-outline",
        },
        {
          title: "Bestillinger",
          path: "orders",
          icon: "mdi-credit-card-plus-outline",
        },
        /* {
          title: "Achievements",
          path: "achievements",
          icon: "mdi-trophy",
        }, */
      ],
    };
  },
  watch: {
    menuItemActive(val) {
      router.push(val);
    },
  },
  methods: {
    async signOut() {
      await auth.signOut();
      await store.dispatch("emptyState");
      router.push("/");
    },
  },
});
